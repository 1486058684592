<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align:left">
                <span>库存列表</span>
            </div>
        </template>
        <div class="content">
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24" style="text-align:left;">
                        <div class="searchblock">
                            <el-button type="primary" size="small" @click="SelectMerchant" style="margin-right:10px;"
                                v-if="ismerchant != 1">选择商户</el-button>
                            <el-cascader :options="option" v-model="category" placeholder="选择品类" clearable
                                style="width:150px;margin-right:10px;" :show-all-levels="false" :props="props"
                                @change="SetBrand" size="small"></el-cascader>
                            <el-select v-model="brand" placeholder="选择品牌" filterable
                                style="margin-right:10px;width:150px" clearable :disabled="category ? false : true"
                                @change="SetModel" size="small">
                                <el-option v-for="brand in brands" :key="brand.ID" :label="brand.Brand"
                                    :value="brand.ID"></el-option>
                            </el-select>
                            <el-select v-model="model" placeholder="选择型号" filterable
                                style="margin-right:10px;width:150px" clearable :disabled="brand ? false : true"
                                @visible-change="GetModel" size="small">
                                <el-option v-for="model in models" :key="model.ID" :label="model.Model"
                                    :value="model.ID"></el-option>
                            </el-select>
                            <el-select v-model="pdt" placeholder="选择商品" filterable style="margin-right:10px;width:150px"
                                clearable :disabled='model ? false : true' @visible-change="GetPdt" size="small">
                                <el-option v-for="pdt in pdts" :key="pdt.ID" :label="pdt.PdtName"
                                    :value="pdt.ID"></el-option>
                            </el-select>
                            <el-input v-model="keyword" placeholder="请输入套餐编码/套餐标题" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small">搜索</el-button>
                            <el-button type="danger" @click="GetNoStock" style="margin-left:10px;"
                                class="hidden-sm-and-down" size="small">查看库存不足套餐</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="small">

                <el-table-column label="套餐编码" width="150" prop="PlanCode"></el-table-column>
                <el-table-column label="套餐标题" prop="PlanTitle"></el-table-column>
                <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                <el-table-column label="所属商品" width="150" prop="PdtName"></el-table-column>
                <el-table-column label="总库存" width="100" prop="TotalStock"></el-table-column>
                <el-table-column label="在库数量" width="100" prop="InStock">
                    <template #default="scope">
                        <span :style="[scope.row.InStock < 10 ? 'color:red;font-weight:bold' : '']">{{ scope.row.InStock
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="预定数量" width="100" prop="ReserveCount"></el-table-column>
                <el-table-column label="在租数量" width="100" prop="RentingCount"></el-table-column>
                <el-table-column label="归还中" width="100" prop="ReturningCount"></el-table-column>
                <el-table-column label="定损中" width="100" prop="EstimateCount"></el-table-column>
                <el-table-column label="已买断" width="100" prop="BuyoutCount"></el-table-column>
                <el-table-column label="操作" width="100">
                    <template #default="scope">
                        <el-button type="primary" @click="EditStock(scope.row.PlanID, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="curpage"
                :page-size="pagesize" :page-sizes='[10, 20, 30, 50, 100]'
                layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                style="text-align:center"></el-pagination>
        </div>
    </el-card>
    <el-dialog title="修改总库存" width="20%" v-model="editstock">
        <div class="editblock">
            <el-input v-model="curstock" @keyup.stop="CheckStock" style="margin-right:10px;"></el-input>
            <el-button type="success" @click="SaveStock" :disabled="correct">保存</el-button>
        </div>
        <el-tag type="danger" style="margin-top:5px;">当前可修改的库存数量不可低于{{ min }}</el-tag>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}

.editblock {

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
    data() {
        return {
            selmerchant: [],
            transfertitle: ['商户列表', '已选商户'],
            merchantlist: [],
            merchant: false,
            load: false,
            curpage: 1,
            pagesize: 10,
            totalcount: 0,
            correct: true,
            curplanid: '',
            min: '',
            curstock: '',
            editstock: false,
            tabledata: [],
            category: '',
            brand: '',
            model: '',
            pdt: '',
            option: [],
            brands: [],
            models: [],
            pdts: [],
            keyword: '',
        }
    },
    methods: {
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back() {
            this.merchant = false;
        },
        SelectMerchant() {
            this.merchant = true;
        },
        GetNoStock() {
            this.axios.get(constant.get_stock_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    status: 2,
                    selmerchant: JSON.stringify(this.selmerchant),
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        SaveStock() {
            if (this.curstock < this.min) {
                this.$message.error("库存数量不可低于" + this.min);
                this.correct = true;
                return false;
            }
            this.axios.get(constant.save_stock_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    planid: this.curplanid,
                    stock: this.curstock,
                }
            }).then((response) => {
                console.log(response);
                if (response.data == "OK") {
                    this.curplanid = "";
                    this.curstock = "";
                    this.min = "";
                    this.correct = true;
                    this.editstock = false;
                    this.$router.go(0);
                }
            })
        },
        CheckStock() {
            if (this.curstock < this.min) {
                this.$message.error("库存数量不可低于" + this.min);
                this.correct = true;
            } else
                this.correct = false;

        },
        EditStock(planid, row) {
            this.min = row.TotalStock - row.InStock;
            this.editstock = true;
            this.curplanid = planid;
        },
        SetBrand() {
            this.brand = "";
            this.model = "";
            this.pdt = "";
        },
        SetModel() {
            this.model = "";
            this.pdt = "";
        },
        GetModel() {
            this.loading = true;
            this.axios.get(constant.get_plan_model_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    status: 1,
                }
            }).then((response) => {
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        GetPdt() {

            this.axios.get(constant.get_plan_pdt_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    status: 1,
                    selmerchant: JSON.stringify(this.selmerchant),
                    merchantid: this.mid,
                }
            }).then((response) => {

                this.pdts = response.data;

            });
        },
        init() {
            this.load = true;
            this.axios.get(constant.get_stock_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    pdt: this.pdt,
                    keyword: this.keyword,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    selmerchant: JSON.stringify(this.selmerchant),
                    ismerchant: this.ismerchant,
                    merchantid: this.mid,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
                this.load = false;
            });
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_stock_url, {
            headers: {
                'Content-Type': 'application/json'
            }, params: {
                curpage: this.curpage,
                pagesize: this.pagesize,
                ismerchant: this.ismerchant,
                merchantid: this.mid,
            }
        }).then((response) => {
            console.log(response.data);
            this.tabledata = response.data.list;
            this.curpage = response.data.curpage * 1;
            this.totalcount = response.data.totalcount * 1;
        });
        this.axios.get(constant.get_cascader_url, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response.data);
            this.option = response.data;
        });
        this.axios.get(constant.get_brand_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                status: 1
            }
        }).then((response) => {
            console.log(response.data);
            this.brands = response.data;
        });

        this.axios.get(constant.allmerchant, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then((response) => {
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for (let i = 0; i < list.length; i++) {
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    }
}
</script>